import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'se-multiSelect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectComponent {
  @Input() dropdownValues: any[];
  @Input() preSelected: string;
  @Input() isDisabled: boolean;

  @Output() changedSelection = new EventEmitter<any>();

  selectedValues: any[];

  ngOnInit() {
    if (this.preSelected) {
      const preSelectedArray = this.preSelected;
      this.selectedValues = this.dropdownValues.filter((item) =>
        preSelectedArray.includes(item.value)
      );
    }
  }

  /**
   * Emits the current selection of values.
   */
  saveSelection() {
    const transformedValues =
      this.selectedValues.length === 0
        ? ['']
        : this.selectedValues.map((item) => item.value);
    this.changedSelection.emit(transformedValues);
  }

  /**
   * Clears the current selection and emits the change.
   */
  clearSelection() {
    this.selectedValues = [];
    this.saveSelection();
  }
}
