import { Component, EventEmitter, Input, Output } from '@angular/core';

import { firstLetterUpperCase } from '@shared/helpers/string-transformation';

@Component({
  selector: 'se-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() dropdownValues: any[];
  @Input() selectedOption: string;
  @Input() isDisabled: boolean;
  @Input() isDarkColor: boolean;
  @Input() isTransformEnabled: boolean;
  @Input() hasValidationErrors: boolean;

  @Output() changedOption = new EventEmitter<any>();

  /**
   * Retrieves the color associated with the selected option.
   * @returns {string | undefined} The color in lowercase if found, otherwise `undefined`.
   */
  getColor(): string | undefined {
    let foundObject = undefined;

    if (this.selectedOption && this.dropdownValues) {
      foundObject = this.dropdownValues.find(
        (obj) => obj.value === this.selectedOption
      );
      if (foundObject && foundObject.value === 'DI2 - Investigation started') {
        foundObject.color = 'darkorange'; // Change color to darkorange
      }
      // If the found object is "DI3 - Measure defined + approved", change its color to pink
      else if (
        foundObject &&
        foundObject.value === 'DI3 - Measure defined + approved'
      ) {
        foundObject.color = 'orange'; // Change color to orange
      }
    }
    return foundObject ? foundObject.color.toLowerCase() : undefined;
  }

  /**
   * Transforms the input string by capitalizing its first letter.
   * @param {string} input - The string to transform.
   * @returns {string} The transformed string with the first letter in uppercase.
   */
  transformString(input: string): string {
    return firstLetterUpperCase(input);
  }

  /**
   * Handles changes in the selected option.
   * @param {Event} event - The change event containing the new value.
   */
  onSelectChange(event: any) {
    const value = event.target.value;
    this.selectedOption = value;
    this.changedOption.emit(value);
  }
}
