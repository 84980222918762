/**
 * Environment Configuration
 * This configuration is used for the development environment.
 */
export const environment = {
  production: false,
  version: '3.4',
  appStoreId: '10059',
  apiUrl:
    'https://api-supplier-capability-assessment-dev.edaa.siemens-energy.cloud/',

  /**
   * Azure Active Directory (Azure AD) Configuration
   */
  azureAD: {
    clientId: '9a939a65-f63e-4960-84ea-86d6f3d676f3',
    authority:
      'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477',
    redirectUri: 'https://superis-dev.edaa.siemens-energy.cloud/',
    apiConfig: {
      scopes: ['9a939a65-f63e-4960-84ea-86d6f3d676f3/.default'],
      uri: 'https://api-supplier-capability-assessment-dev.edaa.siemens-energy.cloud/',
    },
  },
};
