<div class="dialog-action-plan">
  <form
    [formGroup]="actionPlanForm"
    (ngSubmit)="onSubmit()"
    *ngIf="currentStep === 'insertData'"
  >
    <p class="dialog-action-plan__description">
      <b>Main Information</b><br />Select the mandatory fields marked in red to
      submit the action plan.
    </p>
    <div class="row d-flex align-items-stretch flex-wrap">
      <ng-container *ngFor="let field of actionPlansFields[0].values">
        <div
          *ngIf="field.isVisible === 1"
          class="col-12 col-sm-4 col-md-3 dialog-action-plan__field"
        >
          <label
            ><span *ngIf="field.isMandatory && field.isEditable">*</span
            >{{ field.name }}</label
          >

          <ng-container *ngIf="!field.isEditable">
            <!-- NO EDITABLE FIELD -->
            <p>
              {{ actionPlanForm.value[field.id] }}
            </p>
          </ng-container>
          <ng-container *ngIf="field.isEditable">
            <se-dropdown
              *ngIf="field.valueType === 'Dropdown'"
              [hasValidationErrors]="actionPlanForm.get(field.id).errors?.['required']"
              [dropdownValues]="actionPlansDropdownValues[field.id]"
              [selectedOption]="rowData[field.id]"
              [isDisabled]="!field.isEditable"
              (changedOption)="actionPlanForm.get(field.id).setValue($event)"
            ></se-dropdown>

            <!-- INPUT FIELD VALUE -->
            <input
              class="dialog-action-plan__text-field"
              [formControlName]="field.id"
              [type]="field.dataType === 'Integer' ? 'number' : 'text'"
              *ngIf="field.valueType === 'Freetext'"
            />

            <!-- CALENDAR VALUE -->
            <se-calendar
              *ngIf="field.valueType === 'DatePicker'"
              [date]="actionPlanForm.get(field.id).value"
              (changedDate)="actionPlanForm.get(field.id).setValue($event)"
            ></se-calendar>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <!-- if is not main info -->
    <p class="dialog-action-plan__description">
      <b>Additional values</b><br />
      Expand the sections to add additional information. This information can
      also be added/edited later on the Action Plans view.
    </p>
    <p-accordion [activeIndex]="0">
      <p-accordionTab
        class="dialog-action-plan__accordion"
        *ngFor="let category of actionPlansFields.slice(1)"
        [header]="category.categoryTitle"
      >
        {{ category.title }}
        <div class="row">
          <div
            *ngFor="let field of category.values"
            class="col-12 col-sm-6 col-md-4 col-lg-3 dialog-action-plan__field"
          >
            <label
              ><span *ngIf="field.isMandatory && field.isEditable">*</span
              >{{ field.name }}</label
            >
            <ng-container *ngIf="!field.isEditable">
              <!-- NO EDITABLE FIELD -->
              <p>
                {{ actionPlanForm.value[field.id] }}
              </p>
            </ng-container>

            <ng-container *ngIf="field.isEditable">
              <!-- DROPDOWN VALUE -->
              <se-dropdown
                *ngIf="field.valueType === 'Dropdown'"
                [hasValidationErrors]="actionPlanForm.get(field.id).errors?.['required']"
                [dropdownValues]="actionPlansDropdownValues[field.id]"
                [selectedOption]="rowData[field.id]"
                [isDisabled]="!field.isEditable"
                (changedOption)="actionPlanForm.get(field.id).setValue($event)"
              ></se-dropdown>

              <!-- INPUT FIELD VALUE -->
              <input
                [formControlName]="field.id"
                *ngIf="field.valueType === 'Freetext'"
                [maxlength]="field.name === 'Measure Description' ? 1000 : null"
                class="dialog-action-plan__text-field"
              />

              <!-- CALENDAR VALUE -->
              <se-calendar
                *ngIf="field.valueType === 'DatePicker'"
                [date]="actionPlanForm.get(field.id).value"
                [isInvalid]="actionPlanForm.get(field.id).errors?.['required']"
                (changedDate)="actionPlanForm.get(field.id).setValue($event)"
              ></se-calendar>
            </ng-container>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>

    <div class="dialog-action-plan__buttons">
      <button
        type="submit"
        class="se-button se-button--primary btn"
        [disabled]="actionPlanForm.invalid"
      >
        <span>Add new plan</span>
      </button>
    </div>
  </form>

  <div *ngIf="currentStep === 'addingData'">
    <se-loading-spinner
      *ngIf="isCreatingActionPlan"
      text="Creating action plan"
    ></se-loading-spinner>
    <div *ngIf="actionPlanId !== 0" class="dialog-action-plan__success">
      <fa-icon [icon]="faCircleCheck"></fa-icon>
      <p>
        <b>Action Plan created with success. </b><br />
        ID {{ actionPlanId }}
      </p>
    </div>
    <div class="dialog-action-plan__buttons">
      <button
        (click)="viewActionPlans()"
        class="se-button se-button--primary btn"
      >
        <span>View action plans</span>
      </button>
      <button
        (click)="addMorePlans()"
        class="se-button se-button--secondary btn"
      >
        <span>Create new plan</span>
      </button>
    </div>
  </div>
</div>
